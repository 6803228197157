import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Menu from "../components/global/Menu"
import Footer from "../components/global/Footer"
import { graphql, Link } from "gatsby"
import ReactMarkdown from "react-markdown"

const CompanyHoursPage = ({
  data: { strapiCompanyHoursPage, allStrapiLocations },
}) => (
  <Layout bodyTheme="theme-books">
    <SEO
      title="Company Hours"
      description="Razroo Company Hours"
      keywords={[`razroo`, `company`, `hours`]}
    />
    <header>
      <Menu />
    </header>
    <main className="page-wrapper">
      <section className="section section-first">
        <div className="container">
          <div className="page-heading text-center">
            <ReactMarkdown
              source={strapiCompanyHoursPage["companyHoursHeader"]}
            />
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="hours-module">
            <ul className="nav">
              {allStrapiLocations.nodes.map((location, i) => {
                return (
                  <li key={i}>
                    <a
                      href={"#tab-" + location["name"].toLowerCase()}
                      className={i === 0 ? "active" : ""}
                      data-toggle="tab"
                    >
                      {location.name}
                    </a>
                  </li>
                )
              })}
            </ul>
            <div className="tab-content">
              {allStrapiLocations.nodes.map((location, i) => {
                return (
                  <div
                    key={i}
                    id={"tab-" + location["name"].toLowerCase()}
                    className={
                      i === 0
                        ? "tab-pane fade show active"
                        : "tab-pane fade show"
                    }
                  >
                    <div className="table-scroll">
                      <table className="table-hours">
                        <thead>
                          <tr>
                            {Object.keys(location["hours"]).map(day => {
                              return (
                                <>
                                  <th>{day}</th>
                                </>
                              )
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {Object.keys(location["hours"]).map(day => {
                              return (
                                <>
                                  <td>{location["hours"][day]}</td>
                                </>
                              )
                            })}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  </Layout>
)

export const query = graphql`
  query CompanyHoursPage {
    strapiCompanyHoursPage {
      companyHoursHeader
    }

    allStrapiLocations {
      nodes {
        hours {
          Monday
          Tuesday
          Wednesday
          Thursday
          Friday
          Saturday
          Sunday
        }
        name
      }
    }
  }
`
export default CompanyHoursPage
